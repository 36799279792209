// Generated by Framer (7d0044b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["l7a3bIJY1", "zsWcsBwr1"];

const variantClassNames = {l7a3bIJY1: "framer-v-1gvby28", zsWcsBwr1: "framer-v-3j660c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "l7a3bIJY1", "Variant 2": "zsWcsBwr1"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "l7a3bIJY1", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "l7a3bIJY1", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WvW2w", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1gvby28", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"l7a3bIJY1"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({zsWcsBwr1: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WvW2w [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WvW2w * { box-sizing: border-box; }", ".framer-WvW2w .framer-6ow15m { display: block; }", ".framer-WvW2w .framer-1gvby28 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 15px; height: 604px; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1000px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WvW2w .framer-1gvby28 { gap: 0px; } .framer-WvW2w .framer-1gvby28 > * { margin: 0px; margin-left: calc(15px / 2); margin-right: calc(15px / 2); } .framer-WvW2w .framer-1gvby28 > :first-child { margin-left: 0px; } .framer-WvW2w .framer-1gvby28 > :last-child { margin-right: 0px; } }", ".framer-WvW2w.framer-v-3j660c .framer-1gvby28 { align-content: center; align-items: center; justify-content: center; width: 350px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 604
 * @framerIntrinsicWidth 1000
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zsWcsBwr1":{"layout":["fixed","fixed"]}}}
 */
const FramerfVvnatTy4: React.ComponentType<Props> = withCSS(Component, css, "framer-WvW2w") as typeof Component;
export default FramerfVvnatTy4;

FramerfVvnatTy4.displayName = "Archive / Oval Carousel";

FramerfVvnatTy4.defaultProps = {height: 604, width: 1000};

addPropertyControls(FramerfVvnatTy4, {variant: {options: ["l7a3bIJY1", "zsWcsBwr1"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerfVvnatTy4, [])